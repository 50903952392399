body {
  background-color: #014b8d;
}

.login-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
}

.user-card {
  width: 10%;
  padding: 10px;
  text-align: center;
  background-color: #014b8d;
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 6px, rgb(0 0 0 / 32%) 0px 3px 6px;
  cursor: pointer;
}

.user-card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
